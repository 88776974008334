import React from 'react';
import Image from '../Image/Image';

import * as styles from './BigPicture.module.css';

const BigPicture = ({image}) => {
  return (
    <div className={styles.main}>
      <Image image={image} alt={image.alt} className={styles.imageContainer} />
    </div>
  )
}

export default BigPicture;