import React, { useEffect, useState } from "react";
import Img from "gatsby-image";
import classNames from "classnames";
// import SanityMuxPlayer from "sanity-mux-player";
import groq from "groq";

import StreamPlayer from "../StreamPlayer/StreamPlayer";

import client from "../../sanity/client";
import urlFor from "../../sanity/urlFor";

import * as styles from "./Image.module.css";

const Image = ({ image, alt, className, aspectRatio, captionPosition, noLazy, bgColor }) => {
  const [videoAssetData, setVideoAssetData] = useState(() => {
    if (
      image &&
      image.video &&
      image.video.asset &&
      image.video.asset._type === "mux.videoAsset"
    ) {
      return image.video.asset;
    } else {
      return null;
    }
  });

  useEffect(() => {
    const asyncAction = async ref => {
      const query = groq`*[_id == "${ref}"]`;

      try {
        const result = await client.fetch(query);
        if (Array.isArray(result)) {
          setVideoAssetData(result[0]);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (image && image.video && image.video.asset && image.video.asset._ref) {
      asyncAction(image.video.asset._ref);
    }
  }, [image]);

  useEffect(() => {
    // console.log('videoAssetData : ', videoAssetData);
  }, [videoAssetData])

  if (image && image.video && image.video.asset) {
    return (
      <div className={classNames(className, styles.videoContainer)}>
        {videoAssetData && (
          <StreamPlayer
            src={`https://stream.mux.com/${videoAssetData.playbackId}.m3u8`}
            poster={`https://image.mux.com/${videoAssetData.playbackId}/thumbnail.jpg?time=0&width=16`}
            dataPoster={`https://image.mux.com/${videoAssetData.playbackId}/thumbnail.jpg?time=0`}
            noLazy={noLazy}
            bgColor={bgColor}
            // poster={image && image.asset && image.asset.fluid && <Img fluid={image && image.asset && image.asset.fluid} />}
          >
            {alt && (
              <figcaption
                className={classNames({
                  [styles.captionTop]: captionPosition === "overlay-top",
                })}
              >
                {alt}
              </figcaption>
            )}
          </StreamPlayer>
        )}
        {/* {videoAssetData && (
          <SanityMuxPlayer
            assetDocument={videoAssetData}
            autoplay={true}
            showControls={false}
            muted={"muted"}
            loop={true}
            playsinline={true}
            className={styles.muxPlayer}
            style={{
              width: "auto",
              height: "100%",
            }}
          >

          </SanityMuxPlayer>
        )} */}
      </div>
    );
  }

  if (image && image.asset && image.asset._ref) {
    return (
      <div className={classNames(className, styles.imageContainer)}>
        <picture className={styles.picture}>
          <img src={urlFor(image.asset)} alt="" />
          {alt && (
            <figcaption
              className={classNames({
                [styles.captionTop]: captionPosition === "overlay-top",
              })}
            >
              {alt}
            </figcaption>
          )}
        </picture>
      </div>
    );
  }

  if (image && image.asset && image.asset.fluid) {
    return (
      <div className={classNames(className, styles.imageContainer)}>
        <Img fluid={image && image.asset && image.asset.fluid} />
        {alt && (
          <figcaption
            className={classNames({
              [styles.captionTop]: captionPosition === "overlay-top",
            })}
          >
            {alt}
          </figcaption>
        )}
      </div>
    );
  }

  return null;
};

export default Image;
