import React from "react";
import BlockContent from "@sanity/block-content-to-react";

import Image from "../Image/Image";

import * as styles from "./FreshOcean.module.css";

const FreshOcean = ({ image, stats, text }) => {
  return (
    <div id="friskt-hav" className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.image}>
          <Image image={image} className={styles.imageBlock} />
        </div>

        <div className={styles.texts}>
          <div className={styles.textContainer}>
            <BlockContent className={styles.blockContent} blocks={text} />
          </div>
          <div className={styles.statsContainer}>
            {stats &&
              stats.map((stat, index) => (
                <p key={`stat-${index}`} className={styles.stat}>
                  <span>{`${stat.number}%`}</span>
                  <span>{stat.text}</span>
                </p>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreshOcean;
