import React from "react";
import BlockContent from "@sanity/block-content-to-react";

import urlFor from "../../sanity/urlFor";

import * as styles from "./BlockContent.module.css";

const BlockRenderer = (props) => {
  const {style = 'normal'} = props.node;

  if (style === "disclaimer") {
    return <p style={{fontSize: '0.9rem'}}>{props.children}</p>
  }
  
  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props)
}

const serializers = {
  types: {
    block: BlockRenderer,
    captionedImage: ({ node }) => {
      return (
        <picture className={styles.inlinePicture}>
          <img src={urlFor(node.asset)} alt="" />
          {node.alt && <figcaption>{node.alt}</figcaption>}
        </picture>
      );
    },
  },
};

const Block = props => {
  // console.log('props : ', props);
  return(
    <BlockContent
      serializers={serializers}
      projectId="8m0zf2my"
      dataset="production"
      {...props}
    />
  );
};

export default Block;
