import React from 'react';

import Image from '../Image/Image';
import * as styles from './Hero.module.css';

const Hero = ({text, image}) => {
  const splitText = text.split(' ');

  const firstWord = splitText[0];
  const restWords = splitText.slice(1).join(' ');

  return (
    <div className={styles.wrapper}>
      { image && <Image image={image} className={styles.image} noLazy={true} bgColor={"#000"} /> }
      { text && <h1><span>{firstWord}</span><br />{restWords}</h1> }
    </div>
  )
}

export default Hero;