import React from "react";
import BlockContent from "@sanity/block-content-to-react";

import Logo from "../Logo/Logo";
import Image from "../Image/Image";

import * as styles from "./SeeSalmon.module.css";

const SeeSalmon = ({ image, text }) => {
  return (
    <div id="see-salmon" className={styles.wrapper}>
      <div className={styles.container}>
          <div className={styles.texts}>
            <Logo type="see-salmon" className={styles.logo} />
            <BlockContent blocks={text} />
          </div>
          <Image image={image} className={styles.image} />
      </div>
    </div>
  );
};

export default SeeSalmon;
