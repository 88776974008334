/*
 * This file is part of the Norsk Vind Kjampanesite 2019 application.
 *
 * (c) APT AS
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import React, { useEffect, useRef, useState } from 'react';
import { string, bool } from 'prop-types';
import classNames from 'classnames';
import { useInView } from 'react-hook-inview';
import Hls from 'hls.js';


import * as styles from './StreamPlayer.module.css';

/**
 * This is the StreamPlayer component.
 *
 * @author cedric jud <cedric@apt.no>
 *
 * @return {JSX}
 */
const StreamPlayer = ({
  src,
  poster,
  dataPoster,
  display,
  controls,
  className,
  orientation,
  children,
  noLazy,
  bgColor
}) => {
  const [hasEntered, setHasEntered] = useState(() => {
    if (noLazy) {
      return true;
    } else {
      return false;
    }
  });
  const playerRef = useRef(null);
  const [ref, isVisible] = useInView({
    threshold: 0.5,
    // unobserveOnEnter: true,
  });

  const classes = classNames(
    [styles.main],
    [styles.cover],
    { [styles.isVisible]: hasEntered},
    { [styles.contained]: display === 'contained' },
    { [styles.vertical]: orientation === 'vertical' },
    className
  );

  useEffect(() => {
    if (playerRef.current && hasEntered) {
      const video = playerRef.current;
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          // console.log('manifest parsed');
          video.play();
        });
      }
      // hls.js is not supported on platforms that do not have Media Source Extensions (MSE) enabled.
      // When the browser has built-in HLS support (check using `canPlayType`), we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element through the `src` property.
      // This is using the built-in support of the plain video element, without using hls.js.
      // Note: it would be more normal to wait on the 'canplay' event below however on Safari (where you are most likely to find built-in HLS support) the video.src URL must be on the user-driven
      // white-list before a 'canplay' event will be emitted; the last video event that can be reliably listened-for when the URL is not on the white-list is 'loadedmetadata'.
      else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = src;
        video.addEventListener('loadedmetadata', () => {
          // console.log('loadedMetaData');
          video.play();
        });
      }
    }
  }, [playerRef, hasEntered]);

  useEffect(() => {
    if (!hasEntered && isVisible) {
      setHasEntered(true);
    }

    if (hasEntered && isVisible && playerRef.current.paused) {
      playerRef.current.play();
    }

    if (hasEntered && !isVisible && !playerRef.current.paused) {
      playerRef.current.pause();
    }
  }, [isVisible, hasEntered])

  return (
    <div 
      ref={ref} 
      className={classes}
      style={{backgroundColor: bgColor}}
    >
      <video
        src={hasEntered ? src : null}
        poster={!hasEntered ? poster : dataPoster}
        // poster={oster : dataPoster}
        controls={controls}
        ref={playerRef}
        autoPlay
        loop
        preload="none"
        muted="muted"
        playsInline={true}
      />
      {children}
    </div>
  );
};

/**
 * Declare expected prop types.
 *
 * @type {Object}
 */
StreamPlayer.propTypes = {
  src: string,
  poster: string,
  display: string,
  controls: bool,
  className: string,
  orientation: string,
};

/**
 * Declare defaults for non-required props.
 *
 * @type {Object}
 */
StreamPlayer.defaultProps = {
  controls: false,
  orientation: 'horizontal',
};

export default StreamPlayer;
