import React, { useState } from "react";
// import BlockContent from "@sanity/block-content-to-react";
import { useTransition, animated, config } from "react-spring/web.cjs";

import Image from "../Image/Image";
import BlockContent from "../BlockContent/BlockContent";

import useInterval from "../../hooks/useInterval";

import * as styles from "./History.module.css";

const History = ({ text, images }) => {
  const [index, setIndex] = useState(0);
  let transitions = null;

  transitions = useTransition(
    Array.isArray(images) ? images[index] : null,
    item => item && item._key,
    {
      from: { opacity: 0, position: "absolute" },
      enter: { opacity: 1, position: "static" },
      leave: { opacity: 0, position: "absolute" },
      config: config.molasses,
    }
  );

  useInterval(() => {
    if (Array.isArray(images)) {
      setIndex((index + 1) % images.length);
    }
  }, 5000);

  return (
    <div id="historie" className={styles.wrapper}>
      <div className={styles.container}>
      
      {images && Array.isArray(images) && images.length > 1 && (
        <div className={styles.images}>
          {images &&
            transitions &&
            transitions.map(({ item, props, key }) => (
              <animated.div key={key} style={props}>
                <Image image={item} alt={item.alt} />
              </animated.div>
            ))}
        </div>
      )}
      {images && Array.isArray(images) && images.length === 1 && (
        <div className={styles.images}>
          <div>
            <Image image={images[0]} alt={images[0].alt} />
          </div>
        </div>
      )}
      <BlockContent className={styles.blockContent} blocks={text} />
      </div>
    </div>
  );
};

export default History;
