import React from "react";
// import BlockContent from "@sanity/block-content-to-react";
import classNames from 'classnames';

import Image from "../Image/Image";
import BlockContent from "../BlockContent/BlockContent";

import * as styles from "./About.module.css";

const About = ({ text, images }) => {
  let blockquoteIndex = null;
  let preBlockquote = null;
  let blockquote = null;
  let postBlockquote = null;

  if (text && Array.isArray(text)) {
   blockquoteIndex = text.findIndex(mark => mark.style === "blockquote");
  }

  if (blockquoteIndex !== null && blockquoteIndex !== -1) {
    preBlockquote = text.slice(0, blockquoteIndex);
    blockquote = text.slice(blockquoteIndex, blockquoteIndex + 1);
    postBlockquote = text.slice(blockquoteIndex + 1);
  }

  return (
    <div id="om" className={styles.wrapper}>
      <div className={styles.container}>
        {preBlockquote && (
          <BlockContent
            blocks={preBlockquote}
            className={classNames(styles.text, styles.preBlockquote)}
            renderContainerOnSingleChild={true}
          />
        )}

        {images && images[0] && (
          <div className={classNames(styles.image0Container)}>
            <Image image={images[0]} alt={images[0].alt} className={styles.image1} />
          </div>
        )}

        {blockquote ? (
          <BlockContent 
            blocks={blockquote} 
            className={classNames(styles.text, styles.blockquote)}
            renderContainerOnSingleChild={true}
          />
        ) : (
          <BlockContent 
            blocks={text} 
            className={classNames(styles.text, styles.blockquote)}
            renderContainerOnSingleChild={true}
          />
        )}

        {images && images[1] && (
          <div className={classNames(styles.image1Container)}>
            <Image image={images[1]} alt={images[1].alt} className={styles.image1} />
          </div>
        )}

        {postBlockquote && (
          <BlockContent
            blocks={postBlockquote}
            className={classNames(styles.text, styles.postBlockquote)}
            renderContainerOnSingleChild={true}
          />
        )}
      </div>
    </div>
  );
};

export default About;
